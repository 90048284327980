import React from 'react';
import TransformIcon from '../../../static/assets/transformation.svg';
import ChangeIcon from '../../../static/assets/change.svg';
import Innovate from '../../../static/assets/innovate.svg';
const BannerLeadComponent = props => {
  const { isLead } = props;
  return (
    <div className="landing-banner-section">
      <div className="landing-banner-section-wrapper">
        <div className="full-container">
          {isLead ? (
            <div className="row">
              <div className="col-md-4">
                {isLead && (
                  <div
                    className="landing-banner-section-wrapper-text"
                    style={{ justifyContent: 'center' }}
                  >
                    <TransformIcon
                      style={{ flexGrow: '0.1', margin: 'auto 0' }}
                    />
                    <p className="landing-banner-section-wrapper-title">
                      Transform
                    </p>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {isLead && (
                  <div
                    className="landing-banner-section-wrapper-text"
                    style={{ justifyContent: 'center' }}
                  >
                    <ChangeIcon style={{ flexGrow: '0.1', margin: 'auto 0' }} />
                    <p className="landing-banner-section-wrapper-title">
                      Change
                    </p>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {isLead && (
                  <div
                    className="landing-banner-section-wrapper-text"
                    style={{ justifyContent: 'center' }}
                  >
                    <Innovate style={{ flexGrow: '0.1', margin: 'auto 0' }} />
                    <p className="landing-banner-section-wrapper-title">
                      Innovate
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p className="landing-banner-section-wrapper-description">
              The Agreed Approach to Change Management
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default BannerLeadComponent;
